import { Observable, Subject } from 'rxjs';

export interface ScrollDetails {
  offsetTop: number;
  clientHeight: number;
  clientWidth: number;
  iframeHeight: number;
  iframeWidth: number;
  offsetLeft: number;
  scrollLeft: number;
  scrollTop: number;
}

export const getScrollDetails = (): Observable<ScrollDetails> => {
  const o$ = new Subject<ScrollDetails>();
  const interval = setInterval(() => {
    if ((window as any).parentIFrame) {
      (window as any).parentIFrame.getPageInfo((details) => {
        o$.next(details);
      });
      clearInterval(interval);
    }
  }, 500);

  return o$;
};
